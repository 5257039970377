import {
  type CSSVariablesResolver,
  MantineProvider,
  createTheme,
  VariantColorsResolver,
  defaultVariantColorsResolver,
} from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import RouteConfig from "./routes";
import { Notifications } from "@mantine/notifications";

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);
  if (input.variant === "filled-dark") {
    return {
      background: `var(--mantine-color-${input.color}-7)`,
      hover: `var(--mantine-color-${input.color}-5)`,
      border: "",
      color: "var(--mantine-color-gray-0)",
    };
  }
  if (input.variant === "filled-cream") {
    return {
      background: "var(--mantine-color-cream-0)",
      hover: "var(--mantine-color-cream-0)",
      border: "",
      color: "var(--mantine-color-gray-9)",
    };
  }
  if (input.variant === "light") {
    return {
      background: `var(--mantine-color-${input.color}-0)`,
      hover: `var(--mantine-color-${input.color}-1)`,
      border: "",
      color: `var(--mantine-color-${input.color}-5)`,
    };
  }
  if (input.variant === "danger") {
    return {
      background: "var(--mantine-color-red-9)",
      hover: "var(--mantine-color-red-8)",
      color: "var(--mantine-color-white)",
      border: "none",
    };
  }
  return defaultResolvedColors;
};

const theme = createTheme({
  fontFamily: "Roboto",
  fontFamilyMonospace: "Roboto Mono",
  defaultRadius: 0,
  primaryColor: "turquoise",
  variantColorResolver,

  colors: {
    turquoise: [
      "#dffffd",
      "#cffaf6",
      "#a5f2ea",
      "#77e9de",
      "#52e3d4",
      "#38dfce",
      "#24ddcb",
      "#07c4b3",
      "#00ae9f",
      "#00978a",
    ],
    deepRose: [
      "#ffebfa",
      "#fcd3ed",
      "#f6a5d8",
      "#ef74c2",
      "#ea4bae",
      "#e732a3",
      "#e7249e",
      "#cd1689",
      "#b80c7b",
      "#a2006b",
    ],
    oldLace: [
      "#fdf7ec",
      "#f9edd9",
      "#f5daab",
      "#f1c77a",
      "#edb551",
      "#ebaa39",
      "#eaa52c",
      "#d09021",
      "#ba801a",
      "#a16d0d",
    ],
    dimGray: [
      "#fbf3f5",
      "#e7e7e7",
      "#cdcdcd",
      "#b2b2b2",
      "#9a9a9a",
      "#8b8b8b",
      "#848484",
      "#717171",
      "#656565",
      "#5c5557",
    ],
    oliveDrab: [
      "#f6f9f1",
      "#ebf0e3",
      "#d4e0c1",
      "#bdcf9c",
      "#a8c17d",
      "#9ab768",
      "#94b35d",
      "#809d4d",
      "#718b42",
      "#607835",
    ],
    cream: [
      "#f6f4f4",
      "#e6e6e6",
      "#cbcbcb",
      "#afaeae",
      "#989494",
      "#8b8484",
      "#867c7c",
      "#746969",
      "#695e5e",
      "#5f4f4f",
    ],
  },
  other: {
    header: "70px",
    footer: "60px",
    titleHeader: "34px",
  },
});

const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    "--mantine-header-height": theme.other.header,
    "--mantine-footer-height": theme.other.footer,
  },
  light: {},
  dark: {},
});

function App(): JSX.Element {
  return (
    <MantineProvider cssVariablesResolver={resolver} theme={theme}>
      <Notifications />
      <RouteConfig />
    </MantineProvider>
  );
}

export default App;
