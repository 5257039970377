
export class API {
  URL = "https://api.orchesai.com/dev/wl";

  async saveEmail(email: string, options?: { [key: string]: boolean }) {
    return await fetch(this.URL, {
      method: "POST",
      body: JSON.stringify({
        email,
        ...options,
      }),
    })
      .then((res) => {
        if (!res.ok) {
            return res.status;
        }
      })
  }
}
