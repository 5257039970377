import {
  Box,
  Button,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { API } from "../api/api";
import OSAILayout from "../components/layout";
import ToggleBox from "../components/togglebox";
import classes from "../styles/herotext.module.css";

export default function WaitingList() {
  const [submitted, setSubmitted] = useState(false);
  const api = new API();
  /*
https://api.orchesai.com/dev/wl
{
  "emai" : "xxxx@xxxx.com",
  "investment_opportunuties" : true,
  "seeking_partnership": false,
  "product_demo_evaluation" : true,
  "general_information" : false
}
*/
  const interests = [
    {
      id: "investment_opportunuties",
      label: "Investment Opportunuties",
      checked: false,
    },
    {
      id: "seeking_partnership",
      label: "Seeking Partnership",
      checked: false,
    },
    {
      id: "product_demo_evaluation",
      label: "Product Demo/Evaluation",
      checked: false,
    },
    {
      id: "general_information",
      label: "General Information",
      checked: false,
    },
  ];

  const form = useForm({
    initialValues: {
      email: "",
      interests: interests,
    },
    validate: {
      email: (value) =>
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
          ? null
          : "Please enter a valid email address.",
      interests: (value) =>
        value.filter((e) => e.checked).length > 0
          ? null
          : "Please select at least one interest.",
    },
  });
  function submitHandler() {
    return form.onSubmit((values) => {
      const options: { [key: string]: boolean } = {};
      values.interests.forEach((element) => {
        if (element.checked) {
          options[element.id] = true;
        }
      });
      api
        .saveEmail(values.email, options)
        .then((status: number | undefined) => {
          if (status === 409) {
            notifications.show({
              title: "Duplicate Email.",
              message: "The email that you submit is already submitted.",
              color: "red",
            });
          } else {
            setSubmitted(true);
          }
        })
        .catch(() => {
          notifications.show({
            title: "Failed",
            message: "Your mail cannot be saved. Please try again later.",
            color: "red",
          });
        });
    });
  }
  function clickHandler(index: number) {
    let newInterests = form.getValues().interests;
    newInterests[index].checked = !newInterests[index].checked;
    form.setFieldValue("interests", [...newInterests]);
    console.log(form.getValues());
  }
  return (
    <OSAILayout disabledNavbar>
      {submitted ? (
        <Stack className={classes.inner} align="center" py={50} gap={5}>
          <Title order={1}>Great, you are in!</Title>
          <Text>
            You will receive an email from us when we launch our product.
          </Text>
        </Stack>
      ) : (
        <form onSubmit={submitHandler()}>
          <Stack align="center" pb={"md"} gap={0}>
            <Title order={2}>Secure Your Spot on</Title>
            <Title order={2}>Our Exclusive Waiting List!</Title>
          </Stack>
          <Stack pt={"md"} gap={"xl"}>
            <TextInput
              {...form.getInputProps("email")}
              label={"Please enter your email address :"}
              placeholder="johndoe@example.com"
            />
            <Stack>
              <Text size="sm">
                What are you interested in? You may select multiple options.
              </Text>
              <Stack gap={5}>
                <SimpleGrid cols={{ base: 1, xs: 2 }}>
                  {form.getValues().interests.map((element, index) => {
                    return (
                      <ToggleBox
                        error={typeof form.errors.interests !== "undefined"}
                        key={element.label}
                        onClick={() => {
                          clickHandler(index);
                        }}
                        label={element.label}
                        checked={element.checked}
                      />
                    );
                  })}
                </SimpleGrid>
                <Text size="xs" c={"var(--mantine-color-error)"}>
                  {form.errors.interests}
                </Text>
              </Stack>
            </Stack>
            <SimpleGrid cols={2}>
              <Box></Box>
              <Button type="submit">Submit</Button>
            </SimpleGrid>
          </Stack>
        </form>
      )}
    </OSAILayout>
  );
}
