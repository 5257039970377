import {
  Container,
  Group,
  ActionIcon,
  rem,
  Image,
  Stack,
  Text,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandX,
} from "@tabler/icons-react";
import classes from "../styles/footer.module.css";
import logoIcon from "../images/logo-icon.svg";
import logoText from "../images/logo-text.svg";
export function FooterSocial() {
  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Stack align="center" gap={"xs"}>
          {/* <Image src={logoIcon} w={40} /> */}
          <Text className={classes.brandText} c={"gray"}>
            Orchestreate the AI
          </Text>
          <Group gap={0} justify="flex-end" wrap="nowrap">
            <ActionIcon
              target="_blank"
              component="a"
              href="https://www.x.com/orchesai/"
              size="lg"
              color="gray"
              variant="subtle"
            >
              <IconBrandX
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon>
            <ActionIcon
              target="_blank"
              component="a"
              href="https://www.youtube.com/@orchesai"
              size="lg"
              color="gray"
              variant="subtle"
            >
              <IconBrandYoutube
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon>
            <ActionIcon
              target="_blank"
              component="a"
              href="https://www.instagram.com/orchesai/"
              size="lg"
              color="gray"
              variant="subtle"
            >
              <IconBrandInstagram
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon>
          </Group>
        </Stack>
      </Container>
    </div>
  );
}
