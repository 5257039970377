import {
  Button,
  Container,
  Flex,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { API } from "../api/api";
import { Dots } from "../components/dots";
import { FeaturesCards } from "../components/features";
import OSAILayout from "../components/layout";
import classes from "../styles/herotext.module.css";

export default function Home(): JSX.Element {
  const [submitted, setSubmitted] = useState(false);
  const api = new API();
  const form = useForm({
    initialValues: {
      email: "",
    },

    validate: {
      email: (value) =>
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
          ? null
          : "Please enter a valid email address.",
    },
  });

  function submitHandler() {
    return form.onSubmit((values) => {
      api
        .saveEmail(values.email)
        .then((status: number | undefined) => {
          if (status === 409) {
            notifications.show({
              title: "Duplicate Email.",
              message: "The email that you submit is already submitted.",
              color: "red",
            });
          } else {
            setSubmitted(true);
          }
        })
        .catch(() => {
          notifications.show({
            title: "Failed",
            message: "Your mail cannot be saved. Please try again later.",
            color: "red",
          });
        });
    });
  }

  return (
    <OSAILayout disabledNavbar>
      <Container>
        <Container pb={50} pt={"md"} className={classes.wrapper}>
          <Dots className={classes.dots} style={{ left: 0, top: 0 }} />
          <Dots className={classes.dots} style={{ left: 60, top: 0 }} />
          <Dots className={classes.dots} style={{ left: 0, top: 140 }} />
          <Dots className={classes.dots} style={{ right: 0, top: 60 }} />
          {submitted ? (
            <Stack className={classes.inner} align="center" pb={50} gap={5}>
              <Title order={1}>Great, you are in!</Title>
              <Text size="sm">
                You will receive an email from us when we launch our product.
              </Text>
            </Stack>
          ) : (
            <Stack className={classes.inner} pb={50} gap={0}>
              <Stack align="center" pb={"md"} gap={0}>
                <Title order={2}>Secure Your Spot on</Title>
                <Title order={2}>Our Exclusive Waiting List!</Title>
              </Stack>
              <form onSubmit={submitHandler()}>
                <Container className={classes.controls}>
                  <Flex
                    gap={"sm"}
                    direction={{ base: "column", sm: "row" }}
                    wrap="nowrap"
                    w={"80%"}
                  >
                    <TextInput
                      style={{ zIndex: 1 }}
                      w={{ base: "100%", sm: "80%" }}
                      size="md"
                      placeholder="Your Email"
                      {...form.getInputProps("email")}
                    />
                    <Button
                      type="submit"
                      w={{ base: "100%", sm: "20%" }}
                      size="md"
                    >
                      Submit
                    </Button>
                  </Flex>
                </Container>
              </form>
            </Stack>
          )}
          <Container className={classes.inner} p={0} size={600}>
            <Title order={2} className={classes.title}>
              Click 'n Play Text-generative AI Experience
            </Title>
            <Text size="md" c="dimmed" className={classes.description}>
              A comprehensive suite of GenAI and text manipulation tools,
              specifically designed for content creators, empowers them to
              generate brand-consistent, high-quality content effortlessly while
              simplifying even the most complex workflows.
            </Text>
          </Container>
        </Container>
        <FeaturesCards />
      </Container>
    </OSAILayout>
  );
}
