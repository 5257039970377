import {
  AppShell,
  Burger,
  Container,
  ScrollArea,
  type AppShellSectionProps,
  type ContainerProps,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import layoutClasses from "../styles/layout.module.css";
import OSAIHeader from "./header";
import { FooterSocial } from "./footer";
// import OSAISideMenu from "./side-menu";
interface OSAIILayout {
  children: JSX.Element;
  disabledNavbar?: boolean;
  disabledHeader?: boolean;
  headerProps?: AppShellSectionProps;
  navbarProps?: AppShellSectionProps;
  mainProps?: ContainerProps;
  headerRightSection?: JSX.Element;
}
export default function OSAILayout(props: OSAIILayout): JSX.Element {
  const [opened, { toggle }] = useDisclosure(false);
  return (
    <AppShell
      classNames={{ root: layoutClasses.appShell }}
      header={{ height: "var(--mantine-header-height)" }}
      navbar={{ width: 400, breakpoint: "sm", collapsed: { mobile: !opened } }}
      withBorder={false}
    >
      {props.disabledHeader ?? false ? (
        <></>
      ) : (
        <AppShell.Header>
          <AppShell.Section
            classNames={{ section: layoutClasses.headerSection }}
            {...props.headerProps}
          >
            <Container size={1400}>
              <OSAIHeader />
              {props.headerRightSection}
              {props.disabledNavbar ?? false ? (
                <></>
              ) : (
                <Burger
                  opened={opened}
                  onClick={toggle}
                  hiddenFrom={"sm"}
                  size={"sm"}
                />
              )}
            </Container>
          </AppShell.Section>
        </AppShell.Header>
      )}
      {props.disabledNavbar ?? false ? (
        <></>
      ) : (
        <AppShell.Navbar classNames={{ navbar: layoutClasses.navbar }}>
          <AppShell.Section
            p={{ base: "sm", sm: "xl" }}
            pr={{ base: "sm", sm: "md" }}
            classNames={{ section: layoutClasses.navbarSection }}
            {...props.navbarProps}
          >
            {/* <OSAISideMenu /> */}
          </AppShell.Section>
        </AppShell.Navbar>
      )}
      <AppShell.Main
        pt={
          props.disabledHeader ?? false
            ? 0
            : "calc(var(--app-shell-header-offset, 0px) + var(--app-shell-padding))"
        }
        pl={
          props.disabledNavbar ?? false
            ? 0
            : "var(--_main-padding-left,calc(var(--app-shell-navbar-offset, 0px) + var(--app-shell-padding)))"
        }
      >
        <AppShell.Section
          component={ScrollArea}
          classNames={{ section: layoutClasses.mainSection }}
        >
          <Container
            p={{ base: "sm", sm: "xl" }}
            pl={{ base: "sm", sm: props.disabledNavbar ?? false ? "xl" : "md" }}
            pt={{ base: "md", sm: "xl" }}
            {...props.mainProps}
            fluid
          >
            {props.children}
          </Container>
          <FooterSocial></FooterSocial>
        </AppShell.Section>
      </AppShell.Main>
    </AppShell>
  );
}
