import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import WaitingList from "./pages/waiting-list";

const RouteConfig = (): JSX.Element => {
  return (
    <Routes>
      <Route path={"*"} element={<Home />} />
      <Route path={"/wl"} element={<WaitingList />} />
    </Routes>
  );
};
export default RouteConfig;
