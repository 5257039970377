import {
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
  useMantineTheme,
} from "@mantine/core";
import {
  IconGauge,
  IconUser,
  IconCookie,
  IconWand,
  IconCirclesRelation,
  IconSpeakerphone,
  IconFileStack,
  IconPlug,
  IconBubble,
} from "@tabler/icons-react";
import classes from "../styles/featurescard.module.css";
const mockdata = [
  {
    title: "Effortless Text Generation",
    description: "Customize tools with our intuitive GUI",
    icon: IconWand,
  },
  {
    title: "Seamless Workflows",
    description:
      "Create chains effortlessly by connecting tools for complex text generation",
    icon: IconCirclesRelation,
  },
  {
    title: "Multiple GenAI & LLM Integration",
    description: "Diverse outputs from various AI engines",
    icon: IconBubble,
  },
  {
    title: "Brand Identity Alignment",
    description: "Generate text reflecting your brand's voice and tone",
    icon: IconSpeakerphone,
  },
  {
    title: "Knowledge-Based Generation",
    description: "Leverage your knowledge base for precise results",
    icon: IconFileStack,
  },
  {
    title: "3rd Party API Integration",
    description:
      "Seamlessly connect with external tools for expanded functionality",
    icon: IconPlug,
  },
];

export function FeaturesCards() {
  const theme = useMantineTheme();
  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius={0}
      className={classes.card}
      padding="xl"
    >
      <feature.icon
        style={{ width: rem(50), height: rem(50) }}
        stroke={1}
        color={theme.colors.deepRose[5]}
      />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <Container p={0} m={0} size="lg">
      <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl">
        {features}
      </SimpleGrid>
    </Container>
  );
}
