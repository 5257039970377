import { Flex, Image } from "@mantine/core";
import logoIcon from "../images/logo-icon.svg";
import logoText from "../images/logo-text.svg";
export default function OSAIHeader(): JSX.Element {
  return (
    <Flex h={"100%"} align={"center"}>
      <Image src={logoIcon} w={23} mr={"lg"} />
      <Image src={logoText} w={160} />
    </Flex>
  );
}
