import { Button, Card, Paper, TextInput } from "@mantine/core";
import classes from "../styles/togglebox.module.css";
interface IToggleBox {
  label: string;
  checked?: boolean;
  error?: boolean;
  onClick?: () => void;
}

export default function ToggleBox({
  checked,
  label,
  onClick,
  error,
}: IToggleBox) {
  return (
    <Button
      pl={"xs"}
      styles={{
        root: {
          backgroundColor: checked
            ? "var(--mantine-color-turquoise-5)"
            : "white",
          color: checked
            ? "white"
            : error
              ? "var(--mantine-color-error)"
              : "black",
          transition: "all 150ms",
          border: error
            ? "1px solid var(--mantine-color-error)"
            : "1px solid var(--mantine-color-turquoise-5)",
        },
        inner: {
          justifyContent: "start",
          fontWeight: 300,
        },
      }}
      onClick={(event) => {
        if (onClick) {
          onClick();
        }
        event.stopPropagation();
      }}
    >
      {label}
    </Button>
  );
}
